<template>
  <div class="search">
    <div style="font-size: 18px;color: #000000;padding-top: 44px;margin-left: 16px;">请输入车牌号/车牌颜色</div>
    <div style="
      border-radius: 8px;
      margin: 25px 16px;
      background: #FFFFFF;">
      <div style="border: 2px solid #fff;border-radius: 6px;margin: 12px;display: flex;padding-top: 12px;
      align-items: center;">
        <input readonly style="text-align: center;border-radius: 6px;width: 28px;height: 38px;background: #F7F8FA;border: 1px #F7F8FA solid;font-size: 20px;font-weight: bold;" :value="licensePlateUnit[0]" @click="licensePlateDoor = true"/>
        <input readonly style="text-align: center;border-radius: 6px;width: 28px;height: 38px;background: #F7F8FA;border: 1px #F7F8FA solid;font-size: 20px;font-weight: bold;margin-left: 7px;" :value="licensePlateUnit[1]" @click="licensePlateDoor = true"/>
<!--        <span  style="margin-left: 6px;background-color: #000000;height: 6px;width: 6px;border-radius: 50%;display: inline-block"></span>-->
        <input readonly style="text-align: center;border-radius: 6px;width: 28px;height: 38px;background: #F7F8FA;border: 1px #F7F8FA solid;font-size: 20px;font-weight: bold;margin-left: 7px;" :value="licensePlateUnit[2]" @click="licensePlateDoor = true"/>
        <input readonly style="text-align: center;border-radius: 6px;width: 28px;height: 38px;background: #F7F8FA;border: 1px #F7F8FA solid;font-size: 20px;font-weight: bold;margin-left: 7px;" :value="licensePlateUnit[3]" @click="licensePlateDoor = true"/>
        <input readonly style="text-align: center;border-radius: 6px;width: 28px;height: 38px;background: #F7F8FA;border: 1px #F7F8FA solid;font-size: 20px;font-weight: bold;margin-left: 7px;" :value="licensePlateUnit[4]" @click="licensePlateDoor = true"/>
        <input readonly style="text-align: center;border-radius: 6px;width: 28px;height: 38px;background: #F7F8FA;border: 1px #F7F8FA solid;font-size: 20px;font-weight: bold;margin-left: 7px;" :value="licensePlateUnit[5]" @click="licensePlateDoor = true"/>
        <input readonly style="text-align: center;border-radius: 6px;width: 28px;height: 38px;background: #F7F8FA;border: 1px #F7F8FA solid;font-size: 20px;font-weight: bold;margin-left: 7px;" :value="licensePlateUnit[6]" @click="licensePlateDoor = true"/>
        <input readonly style="text-align: center;border-radius: 6px;width: 28px;height: 38px;background: #F7F8FA;border: 1px #F7F8FA solid;font-size: 20px;font-weight: bold;margin-left: 7px;"
               v-if="7 === licensePlateUnit.length - 1" :class="7 === licensePlateUnit.length - 1 ? 'inputBoxActive' : 'inputBox'" :value="licensePlateUnit[7]"/>
        <input readonly style="writing-mode:tb-rl;text-align: center;border-radius: 6px;width: 28px;height: 38px;background: rgba(55, 219, 199, 0.10);border: 1px #37DBC7 dashed;margin-left: 7px;font-size: 10px;color: #37DBC7;"
               v-show="7 !== licensePlateUnit.length - 1" value="新能源"/>
      </div>
      <div style="font-size: 14px;color: #0F1E3F;margin-top: 4px;margin-left: 12px;">车牌颜色</div>
      <div style="margin: 12px;display: flex;justify-content: space-between;">
        <div style="width: 98px;height: 30px;">
          <div style="width: 98px;height: 30px;background: #3A8AFF;border-radius: 6px;font-size: 12px;display: flex;align-items: center;justify-content: center;color: #FFFFFF;"
               @click="changePlateColor('02')">
            {{licensePlateUnit.join('').length>2?licensePlateUnit.join('').substring(0,2)+'·'+licensePlateUnit.join('').substring(2):licensePlateUnit.join('')}}
            </div>
          <img v-if="plateColor=='02'" src="@/assets/images/checked.png" style="width: 14px;height: 14px;position: relative;top:-36px;right: -90px;"  alt=""/>
        </div>
        <div style="width: 98px;height: 30px;">
          <div style="width: 98px;height: 30px;background: #FFC420;border-radius: 6px;font-size: 12px;display: flex;align-items: center;justify-content: center;color: #0F1E3F;"
               @click="changePlateColor('01')">
            {{licensePlateUnit.join('').length>2?licensePlateUnit.join('').substring(0,2)+'·'+licensePlateUnit.join('').substring(2):licensePlateUnit.join('')}}
          </div>
          <img v-if="plateColor=='01'" src="@/assets/images/checked.png" style="width: 14px;height: 14px;position: relative;top:-36px;right: -90px;"  alt=""/>
        </div>
        <div style="width: 98px;height: 30px;">
          <div style="width: 98px;height: 30px;background: linear-gradient( 180deg, #26B46C 0%, #84E2B3 100%);border-radius: 6px;font-size: 12px;display: flex;align-items: center;justify-content: center;color: #0F1E3F;"
               @click="changePlateColor('03')">
            {{licensePlateUnit.join('').length>2?licensePlateUnit.join('').substring(0,2)+'·'+licensePlateUnit.join('').substring(2):licensePlateUnit.join('')}}
          </div>
          <img v-if="plateColor=='03'" src="@/assets/images/checked.png" style="width: 14px;height: 14px;position: relative;top:-36px;right: -90px;"  alt=""/>
        </div>
      </div>
      <div style="margin: 12px;display: flex;justify-content: space-between;padding-bottom: 12px;">
        <div style="width: 98px;height: 30px;">
          <div style="width: 98px;height: 30px;background: #E1E5EB;border-radius: 6px;font-size: 12px;display: flex;align-items: center;justify-content: center;color: #0F1E3F;"
               @click="changePlateColor('23')">
            {{licensePlateUnit.join('').length>2?licensePlateUnit.join('').substring(0,2)+'·'+licensePlateUnit.join('').substring(2):licensePlateUnit.join('')}}
          </div>
          <img v-if="plateColor=='23'" src="@/assets/images/checked.png" style="width: 14px;height: 14px;position: relative;top:-36px;right: -90px;"  alt=""/>
        </div>

        <div style="width: 98px;height: 30px;">
          <div style="width: 98px;height: 30px;background: #0F1E3F;border-radius: 6px;font-size: 12px;display: flex;align-items: center;justify-content: center;color: #FFFFFF;"
               @click="changePlateColor('06')">
            {{licensePlateUnit.join('').length>2?licensePlateUnit.join('').substring(0,2)+'·'+licensePlateUnit.join('').substring(2):licensePlateUnit.join('')}}
          </div>
          <img v-if="plateColor=='06'" src="@/assets/images/checked.png" style="width: 14px;height: 14px;position: relative;top:-36px;right: -90px;"  alt=""/>
        </div>

        <div style="width: 98px;height: 30px;background: #FFFFFF;border-radius: 6px;font-size: 12px;display: flex;align-items: center;justify-content: center;color: #FFFFFF;">
        </div>
      </div>
    </div>
    <div style="margin: 25px 16px;">
      <div style="margin-top:36px;width: 100%;height: 43px;background: #3A8AFF;border-radius: 4px;font-size: 16px;display: flex;align-items: center;justify-content: center;color: #FFFFFF;"
        @click="handleSendCoupon">
        领取优惠券
      </div>
    </div>

    <div v-if="licensePlateDoor">
      <div v-if="licensePlateUnit.length < 1" style="background: #CDD0D9;
        position: relative;
        border-radius: 6px;
        display: flex;
        flex-wrap: wrap;
        justify-items: center;
        align-items: center">
        <span style="border-radius: 6px;
          background: #fff;
          font-size: 18px;
          height: 18px;
          width:18px;
          margin: 3px;
            padding: 6px;
            display: flex;
            align-items: center;
            justify-content: center;" v-for="item in columns" :key="item" @click="pickOn(item)">
          {{item}}
        </span>
        <span style="background: #ACB3BB;
          border-radius: 6px;
          font-size: 18px;
          height: 18px;
          width:18px;
          margin: 3px;
            padding: 6px;
            display: flex;
            align-items: center;
            justify-content: center;" @click="delCarNo">X</span>
      </div>
      <div v-if="licensePlateUnit.length >= 1" style="background: #CDD0D9;
        position: relative;
        border-radius: 6px;
        display: flex;
        flex-wrap: wrap;
        justify-items: center;
        align-items: center">
      <span style="border-radius: 6px;
          background: #fff;
          font-size: 18px;
          height: 18px;
          width:18px;
          margin: 3px;
            padding: 6px;
            display: flex;
            align-items: center;
            justify-content: center;" v-for="item in numberColumns" :key="item" @click="pickOn(item)">
        {{item}}
      </span>
        <div style="display: flex;align-items: center">
          <span style="background: #ACB3BB;
            border-radius: 6px;
          font-size: 18px;
          height: 18px;
          width:18px;
          margin: 3px;
            padding: 6px;
            display: flex;
            align-items: center;
            justify-content: center;" @click="delCarNo">X</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  unicodeSendCoupon,
} from "@/api/system";

export default {
  data() {
    return {
      licensePlateDoor: false,
      activeIndex: 0,
      plateColor:'02',
      licensePlateUnit: [],
      // 支付来源 0 app 1 pda(路内) 2 web 3 道闸码(路外-车道码) 4 场内码(路外-场内码)
      param: '',
      columns: [//省缩写选择
        '京', '津','冀','晋','蒙','辽','吉','黑','沪','苏',
        '浙','皖','闽','赣','鲁','豫','鄂','湘','粤',
        '琼','桂','甘','陕','新','青','宁','渝',
        '川','贵','云','藏','领','使',
      ],
      numberColumns: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0','Q', 'W', 'E',
        'R', 'T', 'Y', 'U', 'I', 'O', 'P', 'A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', 'Z',
        'X', 'C', 'V', 'B', 'N', 'M', '港','澳','学','警'
      ],
    }
  },
  components:{
    // Numplate,
    // WechatOrder,
  },
  mounted() {
    this.param = this.$route.query.param;
  },
  methods: {
    handleSendCoupon(){
      if(this.licensePlateUnit.join('').trim() === '' || this.licensePlateUnit.join('').trim().length<7){
        this.$message.warning('请填写完整车牌号')
        return
      }
      unicodeSendCoupon({
        param: this.param,
        plateNumber: this.licensePlateUnit.join(''),
        color: this.plateColor,
      })
          .then(res =>{
            if (res && res.code === 30) {
              this.$router.push("/sendCouponDetail?param="+JSON.stringify(res.returnObject));
              console.log(res.returnObject)
            }
          })
    },
    changePlateColor(color){
      this.plateColor = color;
    },
    pickOn(value) {
      this.licensePlateDoor = true;
      if (this.licensePlateUnit.length <= 7) {
        this.licensePlateUnit.push(value)
      }
    },
    delCarNo() {
      this.licensePlateUnit.pop();
    },
    confirm() {
      if(this.licensePlateUnit.length >= 7) {
        console.log("车牌是："+this.licensePlateUnit.join(''));
        this.licensePlateDoor = false;
      }
    },
  }
}
</script>


<style scoped>
.search{
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  background-color: #F7F8FA;
  background-image: url("../../assets/images/search_bg.png");
  background-repeat: repeat-x;
  background-size: 100vw;
}

input:focus {
  border-bottom: 3px solid #fff;
  transition: all 0.5s;
}
</style>
